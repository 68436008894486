@import './responsive';


section.feature-header{
  .section-inner{
    h1{
      font-size: $xlarge-font-size !important;
      margin-bottom: 0;
    }
    img{
      max-width: 130px;
      max-height: 130px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $spacing-small;
    }
  }
}

ul.features{
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;

  li.feature{
    margin: $spacing-small $spacing-small * 0.5;
    flex: 0 0 auto;
    width: calc(33% - #{$spacing-small});
    padding-top: 120px;
    background-size: auto 100px;
    background-repeat: no-repeat;
    background-position: center top;

    &.bin{background-image: url("/assets/images/features-bin.svg");}
    &.centralized{background-image: url("/assets/images/features-centralized.svg");}
    &.graph{background-image: url("/assets/images/features-graph.svg");}
    &.search{background-image: url("/assets/images/features-search.svg");}
    &.stack{background-image: url("/assets/images/features-stack.svg");}
    &.user{background-image: url("/assets/images/features-user.svg");}

    p{
      margin: 0;
      padding: 0;
      margin-bottom: 1em;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.mini-features{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .feature{
    padding-top: calc(80px + #{$spacing-small});
    flex: 0 0 auto;
    width: calc(33% - #{$spacing-small});
    text-align: center;

    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: auto 80px;

    display: flex;
    flex-direction: column;

    h2{flex: 0 0 auto;}
    p{flex: 1 0 auto;}
    a{flex: 0 0 auto; width: auto; margin: auto 0; align-self: center; margin-top: $spacing-small}

    &.fast{background-image: url("/assets/images/icon-fast.svg");}
    &.secure{background-image: url("/assets/images/icon-secure.svg");}
    &.light{background-image: url("/assets/images/icon-light.svg");}
  }
}



$module-hero-list-badge-size: 240px;

.module-hero-list{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .module-hero-list-item{
    width: $module-hero-list-badge-size;
    height: $module-hero-list-badge-size;
    margin: $spacing-small;

    position: relative;

    &:before{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      content: "";
      display: block;
      width: $module-hero-list-badge-size;
      height: $module-hero-list-badge-size;
      border-radius: 50% 50%;
      background-color: $background-color-dark;

      transform: scale(0.6);
      opacity: 0;

      transition: opacity 0.3s, transform 0.4s;
    }

    &:hover{
      &:before{
        transform: scale(1);
        opacity: 1;
        transition: opacity 0.3s, transform 0.6s cubic-bezier(0,1.6,.4,1.0);
      }
    }

    &[data-active]{
      &:after{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        content: "";
        display: block;
        width: $module-hero-list-badge-size;
        height: $module-hero-list-badge-size;
        border-radius: 50% 50%;
        background-color: lighten($background-color-light, 8%);

      }
    }

    .module-hero-list-item-content{
      position: relative;
      z-index: 100;
      width: $module-hero-list-badge-size;
      height: $module-hero-list-badge-size;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      padding: $spacing-medium;
      box-sizing: border-box;

      img{
        display: block;
        margin-bottom: $spacing-xsmall;
      }

      h2{
        margin: 0;
        margin-bottom: $spacing-xsmall;
        font-size: $large-font-size;
      }

      p{
        margin-bottom: 0;
      }
    }

  }
}

section.module-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 500px;

  .section-inner{


    > h2{
      font-size: $xlarge-font-size;
      margin-bottom: $spacing-small;
      max-width: $limit-width;
      text-align: center;

    }

    >p{
      font-size: $large-font-size;
      max-width: $limit-width;

      @include limit-width();
    }

    >img{
      width: 100%;
      max-width: 160px;
      max-height: 160px;
      margin-bottom: $spacing-medium;
    }

    >a.button{
      margin-top: $spacing-large;
      font-size: $large-font-size;
    }

  }


  &.view{
    .section-inner{
      > h2{
        &:before{
          background-image: url(/assets/images/module-view-color.svg);
        }
      }
    }
  }
  &.analyze{
    .section-inner{
      > h2{
        &:before{
          background-image: url(/assets/images/module-analyze-color.svg);
        }
      }
    }
  }
  &.resolve{
    .section-inner{
      > h2{
        &:before{
          background-image: url(/assets/images/module-resolve-color.svg);
        }
      }
    }
  }
}

@include responsive-medium() {
  section.feature-header{
    .section-inner{
      img{
        max-width: 90px;
        max-height: 90px;
      }
    }
  }

  .module-hero-list{
    transform: scale(0.9);
  }
}

@include responsive-small() {

  section.feature-header{
    .section-inner{
      h1{
        font-size: $large-font-size !important;
        margin-bottom: $spacing-small;
      }
      img{
        max-width: 60px;
        max-height: 60px;
      }
    }
  }

  ul.features{
    display: block;
    li.feature{
      width: 100%;
    }
  }

  .mini-features{
    display: block;

    .feature{
      width: 90%;
      margin: 0 auto;
      border-bottom: 1px solid $gray-light;
      padding-bottom: $spacing-medium;
      margin-bottom: $spacing-medium;

      a{
        margin-top: 0;
      }
      &:last-child{
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
    
  .hero.centered.module-hero-list-wrapper{

    .section-inner{
      padding: $spacing-small;
    }

    ul.module-hero-list{
      justify-content: center;
      transform: none;

      li.module-hero-list-item{
        flex: 0 0 auto;

        width: auto;
        height: auto;
        margin: 0;
        margin-right: $spacing-small;
        
        a.module-hero-list-item-content{
          width: auto;
          height: auto;
          flex-direction: column;

          padding: 0;

          text-align: center;
          
          h2{
            font-size: $small-font-size;
            opacity: 0.6;
            margin-bottom: 0;
          }

          p{
            display: none;
          }

          img{
            height: 26px;
            margin-bottom: $spacing-xsmall;
            margin-right: 0;
          }
        }

        &[data-active]{
          a.module-hero-list-item-content{
            h2{
              opacity: 1;
            }
          }
        }

        &:before, &:after{
          display: none !important;
        }

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  section.module-section{
    .section-inner{
      p{
        font-size: $base-font-size;
      }
      > ul{
        display: block;
      }

    }
  }
}