section.price-hero{
  >.section-inner{
    padding-top: $spacing-xlarge !important;
    padding-bottom: $spacing-xlarge !important;

    h1{
      font-size: $xxxlarge-font-size;
      font-weight: $bold-font-weight;
    }
    h2{
      font-size: $xlarge-font-size;
      color: $gray-medium;
      @include limit-width();
      margin-bottom: $spacing-medium;
    }

    .button{
      font-size: $large-font-size;
    }
  }
}


.sales-cta{
  text-align: center;
  h2{
    margin-bottom: 0.1em;
  }
  p{
    margin: 0;
  }
}


@include responsive-medium() {

}

@include responsive-small() {
    
}