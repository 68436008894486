//VARIABLES

$animation-length: 7s;

$shadow-size: 5px;
$shadow-opacity: 0.3;

$shadow: 0 4px $shadow-size rgba(black, $shadow-opacity);
$shadow-filter: drop-shadow($shadow);

$db-start-scale: 0.95;
$db-end-scale: 0.6;
$db-transform-amount: 50%;

$hardware-slide-amount: 5%;
$hardware-slide-time: 5%;

//KEYFRAMES

@keyframes hero-animation-db {
  0% {
    opacity: 0;
    transform: scale($db-start-scale);
  }

  20% {
    opacity: 1;
    transform: none;
  }

  50% {
    opacity: 1;
    transform: scale($db-end-scale);
  }

  #{100% - $hardware-slide-time * 3} {
    opacity: 0;
    transform: scale($db-end-scale) translateY($db-transform-amount);
  }

  100% {
    opacity: 0;
  }
}

@keyframes hero-animation-hardware {
  0% {
    opacity: 0;
    transform: translateX(-$hardware-slide-amount);
  }

  #{$hardware-slide-time} {
    opacity: 1;
    transform: none;
  }

  #{33% - $hardware-slide-time} {
    opacity: 1;
    transform: none;
  }

  33% {
    opacity: 0;
    transform: translateX($hardware-slide-amount);
  }

  100% {
    opacity: 0;
  }
}

//IMPLEMENTATION

.page-content .wrapper main {
  section {
    &.index-hero {
      .section-inner {
        .hero-image {
          img {
            &.db {
              animation: hero-animation-db #{$animation-length / 3} infinite;
              z-index: 2;
              transform-origin: 50% 19%;
            }

            &.hardware {
              animation: hero-animation-hardware $animation-length infinite;
              filter: $shadow-filter;

              &.hardware-2 {
                animation-delay: #{$animation-length / 3};
                opacity: 0;
              }

              &.hardware-3 {
                animation-delay: #{($animation-length / 3) * 2};
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}