@import './hero-animation';

.page-content .wrapper main {
  section {
    &.index-hero {
      padding: $spacing-xlarge $spacing-medium;
      overflow: hidden;

      .section-inner {
        display: flex;
        align-items: center;
        max-width: 100%;

        .hero-image {
          width: 100%;
          min-width: 500px;
          max-width: 100%;
          min-height: 400px;
          height: 100%;
          max-height: 600px;
          margin-right: $spacing-large;
          margin-left: -100px;
          position: relative;

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }

        .hero-content {
          text-align: left;
          max-width: 700px;
          width: 100%;

          > h2 {
            font-size: $xlarge-font-size;
            margin-bottom: $spacing-small;
            max-width: $limit-width;
          }

          > p {
            font-size: $large-font-size;
            max-width: $limit-width;

            @include limit-width();
          }

          .hero-button-wrapper {
            display: inline-block;
            margin-right: $spacing-small;

            > a.button {
              margin-top: $spacing-small;
              font-size: $base-font-size;
            }

            &:first-of-type {
              > a.button {
                font-size: $large-font-size;
              }
            }
          }
        }
      }
    }

    &.product-teaser-container {
      .section-inner {
        overflow: hidden;
        padding-bottom: 0;
        text-align: center;
        // border-bottom: 1px solid $gray-ultra-light;
      }
    }

    img.product-teaser {
      display: block;
      width: 100%;
      margin-top: $spacing-medium;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);

      &.small {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.feature-list {
  margin: 0 $spacing-large;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  text-align: left;

  h2 {
    font-size: $large-font-size;
  }

  p {
    font-size: $small-font-size;
  }

  > li {
    flex: 0 0 auto;
    width: calc(33% - #{$spacing-medium});
    padding: 0 ($spacing-medium * 0.5);
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100px auto;
    margin-bottom: $spacing-medium;
    text-align: center;
    display: flex;

    > .feature {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      color: $gray-medium !important;

      > .feature-img {
        margin: $spacing-medium;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
          max-width: 140px;
          max-height: 90px;
        }
      }

      p:last-of-type {
        margin-bottom: $spacing-medium;
      }

      > .button {
        display: block;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.dot-net-logo {
  width: 33%;
  max-width: 140px;
  margin-bottom: $spacing-small;
}

.comparison-chart {
  width: 100%;
  margin: $spacing-large 0;
}

.framework-table {
  width: 100%;
  display: table;
  margin-bottom: $spacing-medium;
  table-layout: fixed;
  .framework-table-column {
    display: table-cell;
    text-align: left;
    font-size: $large-font-size;
    font-weight: $light-font-weight;
    border-right: 1px solid $gray-light;
    padding: 0 $spacing-small;
  }
  .framework-table-column:last-child {
    border-right: none;
  }
}

.testimonials {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  margin: $spacing-large 0;
  position: relative;

  .testimonial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    flex: 0 0 50%;
    padding: 0 $spacing-medium;
    h2 {
      font-size: $large-font-size;
      margin-top: 0.4em;
      margin-bottom: 0.1em;
    }
    p {
      font-style: italic;
    }
    .testimonial-profile {
      background-size: cover;
      margin: 0 auto;
      width: 90px;
      height: 90px;
      background-color: $gray-light;
      border-radius: 50%;
      border: none;
      box-shadow: inset 0px 5px 10px 2px rgba(0, 0, 0, 0.2);
    }
  }

  > *:nth-child(n + 3) {
    display: none;
  }

  .testimonial:first-child:after {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    display: block;
    width: 1px;
    height: 60%;
    background-color: $gray-light;
    align-self: stretch;
  }
}

.configurations {
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: $spacing-large auto;
  .configuration {
    flex: 0 0 auto;
    width: calc(50% - #{$spacing-medium});

    p {
      margin: $spacing-small 0 $spacing-medium 0;
    }
  }
}

.vertical-line {
  border-right: 1px solid $gray-light;
  align-self: stretch;
  margin: $spacing-medium 0;
}

ul.company-list {
  margin: 0;
  padding: $spacing-small 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  li {
    flex: 0 1 auto;
    padding: $spacing-small * 0.5 $spacing-small;
    img {
      display: block;
      height: 65px;
    }
    &.featured {
      flex-grow: 1;
      width: 100%;
      margin-bottom: 1em;
      img {
        margin: 0 auto;
        height: 100px;
      }
    }
  }
}

.for-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ul.for-list {
    padding: 0;
    margin: 0 $spacing-small;
    list-style: none;
    box-sizing: border-box;
    width: calc(50% - #{(($spacing-medium * 0.5))});
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    li {
      padding: 0;
      margin: 0;
      display: table;
      box-sizing: border-box;
      width: 50%;
      flex: 1 1 50%;
      padding-bottom: 50%;
      position: relative;

      > a {
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.02);
        position: absolute;
        top: $spacing-small * 0.5;
        left: $spacing-small * 0.5;
        right: $spacing-small * 0.5;
        bottom: $spacing-small * 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $xlarge-font-size * 0.85;
        padding: $spacing-small;
        line-height: 1em;

        &:hover {
          background-color: $brand-accent;
          color: #fff;
          transition: background-color 0.5s, color 0.25s;
        }
      }
    }
  }
}

.technology-list {
  > h2 {
    text-align: center;
    margin-bottom: $spacing-large;
  }

  .technology-list-item {
    margin-bottom: $spacing-large;

    > h2 {
      font-size: $large-font-size;
      font-weight: $bold-font-weight;
      margin-bottom: $spacing-xsmall;
    }

    > h3 {
      font-size: $base-font-size;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include responsive-medium() {
  img.product-teaser {
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .feature-list {
    display: block;
    li {
      width: 100%;
      max-width: $content-width / 3;
      margin: auto;
      margin-bottom: $spacing-medium;
      display: block;
    }
  }

  .for-list-wrapper {
    ul.for-list {
      li {
        a {
          font-size: $large-font-size;
        }
      }
    }
  }

  .page-content .wrapper main {
    section.index-hero {
      padding-top: $spacing-large;

      .section-inner {
        flex-direction: column;
        align-items: center;
        padding-top: 0;

        .hero-image {
          height: 50vw;
          max-width: 100vw;
          min-height: 270px;
          // margin-bottom: $spacing-medium;
          margin-left: 0;
          margin-right: 0;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}

@include responsive-small() {
  .for-list-wrapper {
    display: block;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    ul.for-list {
      width: 100%;
      margin-left: auto !important;
      margin-right: auto !important;
      margin-bottom: $spacing-small;
      li {
      }
    }
  }

  .testimonials {
    > *:nth-child(1n + 2) {
      display: none;
    }
    .testimonial {
      flex: none;
      width: 100%;

      &:first-child:after {
        display: none;
      }
    }
  }

  .configurations {
    display: block;
    margin: $spacing-medium 0;
    width: 100%;
    .configuration {
      flex: none;
      width: 100%;
      display: block;
    }
  }

  ul.company-list {
    margin: 0;
    padding: $spacing-small 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;

    li {
      flex: 0 1 auto;
      padding: $spacing-small * 0.5 $spacing-small;
      img {
        display: block;
        height: 65px;
      }
      &.featured {
        flex-grow: 1;
        width: 100%;
        margin-bottom: 1em;
        img {
          margin: 0 auto;
          height: 100px;
        }
      }
    }
  }
}
