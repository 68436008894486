.slicknav_menu {
	display:none;
}

#menu-placeholder {
  display: none;
}


header.site-header{
  color: #fff;
  background-color: $background-color-dark;
  border-bottom: 1px solid #fff;

  z-index: 99;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  .wrapper{
    width: 100%;
    max-width: $content-width;
    padding: 0 $spacing-medium;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;


    .site-title{
      display: flex;
      flex: 0 0 auto;
      align-items: center;

      a{
        display: block;
        width: 150px;
        height: 48px;
        background-image: url("/assets/images/vistadb-white.svg");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        text-indent: - 100vw;
      }

      &.active{
        background-image: url("/assets/images/nav-indicator.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
      }
    }

    nav.site-nav{
      flex: 1 0 auto;

      ul{
        display: flex;
        flex-direction: row;
        list-style: none;
				justify-content: flex-end;
        margin: 0;
        padding: 0;

        li{
          display: flex;
          padding: $spacing-small 0;
          flex: 0 1 auto;
          align-items: center;
          justify-content: center;

          &.burger-home{
            display: none;
          }

          &.active{
            background-image: url("/assets/images/nav-indicator.svg");
            background-position: center bottom;
            background-repeat: no-repeat;
          }

          a, a:hover, a:visited, a:active{
            padding: 0.6em 1.2em;
            color: #fff;
            text-decoration: none;
          }
          a.button{
            margin: 0 0.6em;
            padding: 0.5em 1.2em;
          }
          a:last-child{
            margin-right: 0;
          }

					a.external{
						padding-right: 26px;
						background: url("/assets/images/share.svg") right center no-repeat;
						background-position: calc(100% - 6px) 50%;
						background-size: 14px 14px;
					}
        }
      }
    }

  }
}
nav.sub-nav{
  background-color: $background-color;
  border-bottom: 1px solid #fff;

  ul{
    box-sizing: border-box;
    width: 100%;
    max-width: $content-width;
    margin: 0 auto;
    padding: 0 $spacing-medium;
    display: flex;
    flex-direction: row;

    li{
      list-style: none;
      flex: 0 1 auto;
      color: rgba(255,255,255,0.45);

      a{
        display: block;
        box-sizing: border-box;
        padding: $spacing-small 0;
        margin-right: $spacing-small;
        color: white;
      }

      &.active{
        color: #fff;
      }
    }
  }
}

.viewer-banner{
  background-color: $gray-ultra-light;

  .wrapper{
    width: 100%;
    max-width: $content-width;
    padding: $spacing-small $spacing-medium;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    h2{font-size: $large-font-size; font-weight: $bold-font-weight; margin: 0;}
    p{margin: 0; padding: 0}

    .viewer-banner-left{
      flex: 1 1 auto;
      margin-right: $spacing-medium;
    }
    .viewer-banner-right{
      flex: 0 0 auto;
    }
  }
}

@include responsive-medium() {

  header.site-header{
    .wrapper{
      .site-title{
        margin-right: $spacing-small;
        a{
          width: 100px;
          height: 32px;
        }
      }

    }
  }

}

@include responsive-small() {

  nav.sub-nav{
    ul{
      padding-left: $spacing-small;
      padding-right: $spacing-small;
    }
  }

  header.site-header{
    .wrapper{
      align-items: flex-start;
      padding-left: $spacing-small;
      padding-right: $spacing-small;

      .site-title{
        flex: 0 0 auto;
        align-items: center;

        a{
          width: 75px;
          height: 50px;
        }
        &.active{
          background: none;
        }

      }

      nav.site-nav{
        flex: 1 1 50%;
        & > ul{
          display: none;
        }
      }

    }
  }

}